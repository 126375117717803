export interface windowInterfaceConsole {
  consolere: consolere
}
interface consolere {
  server?: any;
}
export const addRemoteLogging = (keyId: string | null) => {
  try {
    const headerDoc = document.getElementsByTagName("head")[0]
    const remoteDebugChannelIDValue = keyId || localStorage.getItem('remoteDebugLogChannelID') || ''

    const cons = document.createElement("script")
    const script = document.getElementById('consolerescript')
    if (script) return enableRemoteLogging()
    cons.type = "text/javascript"

    cons.src = `${process.env.REACT_APP_DOMAIN}/static/js/connector.js`
    cons.setAttribute("data-server", "https://js-console.vimai.io/")
    cons.setAttribute("data-channel", remoteDebugChannelIDValue)
    cons.setAttribute("data-options", "redirectDefaultConsoleToRemote[true], disableDefaultConsoleOutput[true]")
    cons.id = "consolerescript"

    headerDoc.appendChild(cons)
    cons.onload = () => {
      localStorage.setItem("adapter_debugging", 'enabled')
      // removeRemoteLogging()
    }
  } catch (e) {
    console.log('addRemoteLogging error', e)
  }
}

export const enableRemoteLogging = () => {
  try {
    localStorage.setItem("adapter_debugging", 'enabled')
    const script = document.getElementById('consolerescript')
    if (script) {
      return
    }
  } catch (e) {
    console.log('enableRemoteLogging error', e)
  }
}

export const disableRemoteLogging = () => {
  localStorage.setItem("adapter_debugging", 'false')

  try {
    (window as windowInterfaceConsole).consolere.server = false
  } catch (e) {
    console.log('removeRemoteLogging error', e)
  }
}