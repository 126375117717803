/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faCalendarAlt, faTv } from '@fortawesome/free-solid-svg-icons'
import { keyCode } from 'common/utils'
import Epg from 'features/epg/components/Epg'
import { setIsShowEpg, setPlayerData } from 'features/player/playerSlice'
import { setIsRewatchEpg, setLinkPlay } from 'features/epg/epgSlice'
import VideoJS from 'components/Video'
import { logoMenu } from '../../../constant/images'
import useStreamLimit from '../hooks/streamingLimit'
import Cwl from './cwl'
import useEventFirebase from 'features/player/hooks/eventFirebase'

import '../styles/player-epg.scss'

const PlayerEpg = () => {
  const history = useHistory()
  const playerRef = useRef(null)
  const modalEpgRef = useRef()

  const dispatch = useDispatch()
  const playerData = useSelector((state) => state.player.playerData)
  const isShowEpg = useSelector((state) => state.player.isShowEpg)
  const channelId = useSelector((state) => state.epg.channelId)
  const playerDataLive = useSelector((state) => state.player.playerDataLive)
  // const linkPlay = useSelector((state) => state.epg.linkPlay)
  const [loadingPlayer, setLoadingPlayer] = useState(false)
  const [options, setOptions] = useState(null)
  const [src, setSrc] = useState('')
  const [player, setPlayer] = useState(null)
  const [title, setTitle] = useState('')
  const [active, setActive] = useState('playPause') // playPause hoặc back
  const [status, setStatus] = useState('play') // trạng thái play/pause
  const [showInfo, setShowInfo] = useState(true)
  const [timeoutVar, setTimeoutVar] = useState(null)

  const [isBlackOut, setIsBlackOut] = useState(false)

  const {
    setupStreamLimit,
    handleRetry,
    handleKeyLimit,

    isLimit,
    loadingLimit,
    activeCwl
  } = useStreamLimit({ playerRef })

  const { checkLogEvent } = useEventFirebase(playerRef)

  const eventListener = useCallback(() => {
  }, [playerData, player, active, status, showInfo, timeoutVar, isShowEpg, isBlackOut, isLimit, activeCwl, loadingLimit])

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    if (document.getElementsByClassName('vjs-play-control') && document.getElementsByClassName('vjs-play-control')[0]) {
      document.getElementsByClassName('vjs-play-control')[0].addEventListener('mousedown', handleClickPlayPause)
    }
    return () => {
      document.removeEventListener('keydown', handleKey)
      if (document.getElementsByClassName('vjs-play-control') && document.getElementsByClassName('vjs-play-control')[0]) {
        document.getElementsByClassName('vjs-play-control')[0].removeEventListener('mousedown', handleClickPlayPause)
      }
    }
  }, [eventListener])

    useEffect(() => {
      window.onpopstate = () => {
        if (!isShowEpg) {
          if (timeoutVar) {
            clearTimeout(timeoutVar)
          }
  
          dispatch(setIsRewatchEpg(false))
          dispatch(setLinkPlay(''))
          dispatch(setIsShowEpg(false))
          modalEpgRef.current.disposeModal()
          history.goBack()
        }
      }

    return () => {
      console.clear()

      if (timeoutVar) {
        clearTimeout(timeoutVar)
      }
    }
  }, [])

  // useEffect(() => {
  //   return () => {
  //     if (timeoutVar) {
  //       clearTimeout(timeoutVar)
  //     }
  //   }
  // }, [timeoutVar])

  useEffect(() => {
    if (playerData) {
      // console.log('playerData Epg', playerData)

      setSrc(playerData?.linkPlay)
      setTitle(playerData.title)

      setOptions({
        loop: false,
        autoplay: true,
        muted: false,
        controls: true,
        techOrder: ['html5'],
        preload: 'auto',
        bigPlayButton: false,
        inactivityTimeout: 0,
        errorDisplay: false,
        html5: {
          nativeTextTracks: false,
          vhs: {
            overrideNative: true,
            enableLowInitialPlaylist: true,
            useDevicePixelRatio: true,
            useBandwidthFromLocalStorage: false,
            limitRenditionByPlayerDimensions: false,
            smoothQualityChange: false
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        },
        sources: [{
          type: 'application/x-mpegURL',
          // src: isRewatchEpg ? linkPlay : playerData?.linkPlay
          src: playerData?.linkPlay
        }]
      })
    }
  }, [playerData])

  useEffect(() => {
    if (player) {
      // Buffering
      player.on('waiting', () => {
        // if (toast && toast._element) {
        //   timeoutBuffering = setTimeout(() => {
        //     toast.show()
        //   }, 60000)
        // }
      })

      player.on('playing', () => {
        // clearTimeout(timeoutBuffering)
      })

      player.on('contentchanged', () => {
        console.log('contentchanged', 'contentchanged')
        // clearTimeout(timeoutBuffering)
      })

      // End Video
      // player.on('ended', () => {
      //   if (playerData.episode) {

      //     if (playerData.episode.length === 0) {
      //       history.goBack()
      //     }
      //   }
      // })

      if (document.getElementsByClassName('vjs-play-control') && document.getElementsByClassName('vjs-play-control')[0]) {
        document.getElementsByClassName('vjs-play-control')[0].classList.add('active')
      }

      // Finish init player
      setShowInfo(true)
    }

    return () => {
      if (player) {
        player.dispose()
        dispatch(setIsRewatchEpg(false))
        dispatch(setLinkPlay(''))
      }
    }
  }, [player])

  useEffect(() => {
    if (showInfo) {
      if (document.getElementsByClassName('vjs-control-bar') && document.getElementsByClassName('vjs-control-bar')[0]) {
        document.getElementsByClassName('vjs-control-bar')[0].style.visibility = 'visible'
        document.getElementsByClassName('vjs-control-bar')[0].style.opacity = 1
        document.getElementsByClassName('vjs-control-bar')[0].style.transition = 'visibility 0s linear 0s, opacity 300ms'
      }

      if (document.getElementsByClassName('vjs-text-track-display') && document.getElementsByClassName('vjs-text-track-display')[0]) {
        document.getElementsByClassName('vjs-text-track-display')[0].style.bottom = '0'
        document.getElementsByClassName('vjs-text-track-display')[0].style.backgroundColor = 'black'
        document.getElementsByClassName('vjs-text-track-display')[0].style.opacity = '0.7'
      }

      setTimeoutVar(setTimeout(() => {
        setShowInfo(false)
        if (document.getElementsByClassName('vjs-control-bar') && document.getElementsByClassName('vjs-control-bar')[0]) {
          document.getElementsByClassName('vjs-control-bar')[0].style.visibility = 'hidden'
          document.getElementsByClassName('vjs-control-bar')[0].style.opacity = 0
          document.getElementsByClassName('vjs-control-bar')[0].style.transition = 'visibility 0s linear 300ms, opacity 300ms'
        }
        if (document.getElementsByClassName('vjs-text-track-display') && document.getElementsByClassName('vjs-text-track-display')[0]) {
          document.getElementsByClassName('vjs-text-track-display')[0].style.bottom = ''
          document.getElementsByClassName('vjs-text-track-display')[0].style.backgroundColor = ''
          document.getElementsByClassName('vjs-text-track-display')[0].style.opacity = ''
        }
      }, 4000))
    }
  }, [showInfo])

  useEffect(() => {
    if (player) {
      if (isBlackOut) {
        player?.pause()
      } else {
        player?.player()
      }
    }
  }, [isBlackOut, player])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handlePlayerReady = (player) => {
    playerRef.current = player
    setPlayer(player)
    checkLogEvent(player)
    setupStreamLimit(playerRef.current)
  }

  useEffect(() => {
    if (isLimit) {
      setPlayer(null)
    }
  }, [isLimit])
    
  useEffect(() => {
    setLoadingPlayer(false)
    setTimeout(() => {
      setLoadingPlayer(true)
    }, [200])
  }, [playerData?.id])

  const disposeVis = () => {
    setPlayer(null)
    playerRef.current?.dispose()
    playerRef.current = null
  }


  const handleKey = (e) => {
    e.preventDefault()
    if (isLimit) {
      return handleKeyLimit(e)
    }

    switch (e.keyCode) {
      case keyCode.RETURN: {
        if (isLimit) {
          history.goBack()
        }
        if (!isShowEpg) {
          if (timeoutVar) {
            clearTimeout(timeoutVar)
          }

          // if (timeoutBuffering) {
          //   clearTimeout(timeoutBuffering)
          // }

          // if (toast && toast._element) {
          //   toast.dispose()
          // }

          dispatch(setIsRewatchEpg(false))
          dispatch(setLinkPlay(''))
          dispatch(setIsShowEpg(false))
          modalEpgRef.current.disposeModal()
          history.goBack()
        }
        break
      }
      case keyCode.EXIT: {
        if (isLimit) {
          history.goBack()
        }
        if (!isShowEpg) {
          if (timeoutVar) {
            clearTimeout(timeoutVar)
          }

          // if (timeoutBuffering) {
          //   clearTimeout(timeoutBuffering)
          // }

          // if (toast && toast._element) {
          //   toast.dispose()
          // }

          dispatch(setIsRewatchEpg(false))
          dispatch(setLinkPlay(''))
          dispatch(setIsShowEpg(false))
          modalEpgRef.current.disposeModal()
          history.goBack()
        }
        break
      }
      case keyCode.ENTER: {
        if (!isShowEpg) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'back') {
              if (timeoutVar) {
                clearTimeout(timeoutVar)
              }
              history.goBack()
            }
            if (active === 'playPause' && status === 'play') {
              if (timeoutVar) {
                clearTimeout(timeoutVar)
              }
              player?.pause()
              setStatus('pause')
            }
            if (active === 'playPause' && status === 'pause') {
              setTimeoutVar(setTimeout(() => {
                setShowInfo(false)
                if (document.getElementsByClassName('vjs-control-bar') && document.getElementsByClassName('vjs-control-bar')[0]) {
                  document.getElementsByClassName('vjs-control-bar')[0].style.visibility = 'hidden'
                  document.getElementsByClassName('vjs-control-bar')[0].style.opacity = 0
                  document.getElementsByClassName('vjs-control-bar')[0].style.transition = 'visibility 0s linear 300ms, opacity 300ms'
                }
                if (document.getElementsByClassName('vjs-text-track-display') && document.getElementsByClassName('vjs-text-track-display')[0]) {
                  document.getElementsByClassName('vjs-text-track-display')[0].style.bottom = ''
                  document.getElementsByClassName('vjs-text-track-display')[0].style.backgroundColor = ''
                  document.getElementsByClassName('vjs-text-track-display')[0].style.opacity = ''
                }
              }, 4000))
              player?.play()
              setStatus('play')
            }

            if (active === 'schedule') {
              dispatch(setIsShowEpg(true))
            }

            if (active === 'reset') {
              dispatch(setPlayerData({
                ...playerData,
                id: playerDataLive?.id,
                title: playerDataLive?.title,
                views: playerDataLive?.views,
                linkPlay: playerDataLive?.link_play,
                content_type: playerDataLive?.type,
                content_title: ''
              }))
              dispatch(setIsShowEpg(false))
              dispatch(setIsRewatchEpg(false))
              history.replace('/playerlive')
            }
          }
        }
        break
      }
      // Hiển thị thông tin khi đang ẩn
      // Từ nút player --> nút Back
      case keyCode.UP: {
        if (!isShowEpg) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'playPause') {
              setActive('schedule')
              if (document.getElementsByClassName('vjs-play-control') && document.getElementsByClassName('vjs-play-control')[0]) {
                document.getElementsByClassName('vjs-play-control')[0].classList.remove('active')
              }
            }
          }
        }
        break
      }
      // Hiển thị thông tin khi đang ẩn
      // Từ nút Back --> nút player
      case keyCode.DOWN: {
        if (!isShowEpg) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'back' || active === 'schedule') {
              setActive('playPause')
              if (document.getElementsByClassName('vjs-play-control') && document.getElementsByClassName('vjs-play-control')[0]) {
                document.getElementsByClassName('vjs-play-control')[0].classList.add('active')
              }
            }
          }
        }
        break
      }
      // Hiển thị thông tin khi đang ẩn
      // Backward
      case keyCode.LEFT: {
        if (!isShowEpg) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'back') {
              setActive('reset')
            }
            if (active === 'reset') {
              setActive('schedule')
            }
            if (active === 'schedule') {
              setActive('back')
            }
            if (active === 'playPause') {
              player.currentTime(player.currentTime() - 15)
            }
          }
        }
        break
      }
      // Hiển thị thông tin khi đang ẩn
      // Foward
      case keyCode.RIGHT: {
        if (!isShowEpg) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'back') {
              setActive('schedule')
            }
            if (active === 'schedule') {
              setActive('reset')
            }
            if (active === 'reset') {
              setActive('back')
            }
            if (active === 'playPause') {
              player.currentTime(player.currentTime() + 15)
            }
          }
        }
        break
      }
      case keyCode.MEDIA_PLAY_PAUSE:
      case keyCode.MEDIA_PAUSE:
      case keyCode.MEDIA_PLAY: {
        if (player) {
          if (!isShowEpg) {
            if (status === 'play') {
              player?.pause()
              setStatus('pause')
            } else {
              player?.play()
              setStatus('play')
            }
          }
        }
        break
      }
      case keyCode.MEDIA_REWIND: {
        if (!isShowEpg && player) {
          player.currentTime(player.currentTime() - 15)
        }
        break
      }
      case keyCode.MEDIA_FAST_FORWARD: {
        if (!isShowEpg && player) {
          player.currentTime(player.currentTime() + 15)
        }
        break
      }
    }
  }

  const handleClickBack = () => {
    if (timeoutVar) {
      clearTimeout(timeoutVar)
    }
    history.goBack()
  }

  const handleClickPlayPause = (e) => {
    e.preventDefault()

    if (status === 'play') { // Pause Video
      if (timeoutVar) {
        clearTimeout(timeoutVar)
      }
      setActive('playPause')
      if (document.getElementsByClassName('vjs-play-control') && document.getElementsByClassName('vjs-play-control')[0]) {
        document.getElementsByClassName('vjs-play-control')[0].classList.add('active')
      }
      setStatus('pause')
    }
    if (status === 'pause') { // Play Video
      setTimeoutVar(setTimeout(() => {
        setShowInfo(false)
        if (document.getElementsByClassName('vjs-control-bar') && document.getElementsByClassName('vjs-control-bar')[0]) {
          document.getElementsByClassName('vjs-control-bar')[0].style.visibility = 'hidden'
          document.getElementsByClassName('vjs-control-bar')[0].style.opacity = 0
          document.getElementsByClassName('vjs-control-bar')[0].style.transition = 'visibility 0s linear 300ms, opacity 300ms'
        }
        if (document.getElementsByClassName('vjs-control-bar') && document.getElementsByClassName('vjs-control-bar')[0]) {
          document.getElementsByClassName('vjs-text-track-display')[0].style.bottom = ''
          document.getElementsByClassName('vjs-text-track-display')[0].style.backgroundColor = ''
          document.getElementsByClassName('vjs-text-track-display')[0].style.opacity = ''
        }
      }, 4000))
      setActive('playPause')
      if (document.getElementsByClassName('vjs-play-control') && document.getElementsByClassName('vjs-play-control')[0]) {
        document.getElementsByClassName('vjs-play-control')[0].classList.add('active')
      }
      setStatus('play')
    }
  }

  const handleClickShowEpg = () => {
    setActive('schedule')
    dispatch(setIsShowEpg(true))
  }


  const handleEndVjs = () => {
    console.log('ended video')
  }

  if (isLimit) {
    return (<Cwl handleRetry={handleRetry} loadingLimit={loadingLimit} activeCwl={activeCwl} />)
  }
  return (
    <div className="player-epg">
      <div className="row">
        <div className="col col-md-12 player-epg-col">
          <FontAwesomeIcon
            className={`player-epg-back-icon ${showInfo ? 'visibility-visible' : 'visibility-hidden'} ${active === 'back' ? 'active' : ''}`}
            icon={faChevronLeft}
            onClick={handleClickBack}
          />

          <FontAwesomeIcon
            className={`player-epg-schedule-icon ${showInfo ? 'visibility-visible' : 'visibility-hidden'} ${active === 'schedule' ? 'active' : ''}`}
            icon={faCalendarAlt}
            onClick={handleClickShowEpg}
          />

          <FontAwesomeIcon
            className={`player-epg-reset-live ${showInfo ? 'visibility-visible' : 'visibility-hidden'} ${active === 'reset' ? 'active' : ''}`}
            icon={faTv}
            onClick={handleClickShowEpg}
          />

          <div className={`player-epg-icon-warp ${showInfo ? 'visibility-visible' : 'visibility-hidden'}`}>
            <div className='player-live-icon-white-dot' />
            <div className='player-live-icon-text'>Phát lại</div>
          </div>

          <div className={`player-epg-title ${showInfo ? 'visibility-visible' : 'visibility-hidden'}`}>{title}</div>

          <div className="video-js-container" style={{ width: '100%', height: window.innerHeight }}>
            {/* <video ref={playerRef} className="video-js vjs-default-skin vjs-fill"></video> */}

            {isBlackOut ? (
              <div className='player-live-blackout-wrapper'>
                <img className='player-live-blackout-logo' src={logoMenu} />
                <div className='player-live-blackout-title'>Thông báo</div>
                <div className='player-live-blackout-message'>Chương trình tạm thời bị giáng đoạn vì lý do bản quyền.</div>
                <div className='player-live-blackout-message'>Mong quý khán giả thông cảm và quay lại sau.</div>
              </div>
            ) : null}

            {
              loadingPlayer && options ?
              (<VideoJS src={src} videoIdTag='vimai-video-player-epg'  disposeVis={disposeVis} onReady={handlePlayerReady} player={player} handleEndVjs={handleEndVjs} />)
              : null
            }
          </div>

          {/* Kênh EPG */}
          <Epg player={player} isRewatch={true} ref={modalEpgRef} channelId={channelId} title={title} setIsBlackOut={(value) => setIsBlackOut(() => value)} />
        </div>
      </div>

      {/* <div ref={toastRef} className="toast align-items-center text-white" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="d-flex">
          <div className="toast-body">
            Kết nối mạng có vấn đề, vui lòng kiểm tra.
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default PlayerEpg
