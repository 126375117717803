import * as shaka from 'shaka-player'
import jquery from 'jquery'

let video
let player
let vjsPlayer
let detail = {}
let timer = null
let manifestUrl = ''


function setWidevineDataResponse(response) {
  const wrappedArray = new Uint8Array(response.data)

  // Convert it to a string.
  const wrappedString = String.fromCharCode.apply(null, wrappedArray)

  // Parse the JSON string into an object.
  let wrapped
  try {
    wrapped = JSON.parse(wrappedString)
  } catch (err) {
    console.log('err', err)
    throw new Error('Error while parsing JSON: ' + err)
  }
  // This is a base64-encoded version of the raw license.
  const rawLicenseBase64 = wrapped['license']
  // wrapped['license']
  // Decode it to a string.

  const rawLicenseString = atob(rawLicenseBase64)

  // Convert that string into a Uint8Array and replace the response data to
  // feed it to the Widevine CDM.
  response.data = new Uint8Array(rawLicenseString.length)
  for (let i = 0; i < rawLicenseString.length; ++i) {
    response.data[i] = rawLicenseString.charCodeAt(i)
  }
  return response.data
}

async function setupDRMToday() {
  /* eslint-disable camelcase */
  await getCenc()
  try {
    playStream()
  } catch {
    console.log('err')
    return
  }
}

async function getCenc() {
  const {
    widevide_license_path,
    playready_license_path,
    user_id,
    session,
    merchant,
    auth_token
  } = detail.drmSession

  player.configure({
    drm: {
      servers: {
        'com.widevine.alpha': widevide_license_path
        // 'com.microsoft.playready': playready_license_path
      },
      advanced: {
        'com.widevine.alpha': {
          videoRobustness: 'SW_SECURE_CRYPTO',
          audioRobustness: 'SW_SECURE_CRYPTO'
        }
      }
    }
  })

  const net = player.getNetworkingEngine()
  const requestTypes = shaka.net.NetworkingEngine.RequestType
  // Setting up the License Request

  net.registerRequestFilter(function (type, request) {
    if (type === requestTypes.LICENSE) {
      let drmTodayData = {
        userId: user_id,
        sessionId: session,
        merchant
      }
      if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
        if (auth_token) {
          request.headers['x-dt-auth-token'] = auth_token
        }
        drmTodayData = btoa(JSON.stringify(drmTodayData))
        request.headers['dt-custom-data'] = drmTodayData
        request.headers['x-dt-custom-data'] = drmTodayData
        // request.headers[' Content-Type'] = 'application/xml'
      }
    }
  })

  // Setting up the license response
  net.registerResponseFilter(function (type, response) {
    // jquery('#debug-shakaaaa').append(`<p>registerResponseFilter response: ${JSON.stringify(response)}</p> `)
    if (type === requestTypes.LICENSE) {
      const keySystem = player.keySystem()
      if (keySystem === 'com.widevine.alpha') {
        setWidevineDataResponse(response)
      }

      // For Playready the data returned by DRMToday doesn't need handling and
      // can be sent directly to the CDM.
    }
  })
  return new Promise((resolve) => {
    setTimeout(() => {
      return resolve()
    }, 1000)
  })
}

function playStream() {
  player
    ?.unload?.()
    ?.then(function () {
      return player?.load(manifestUrl)
    })
    .then(function () {
      console.log('Playing stream')
      if (vjsPlayer.paused()) {
        const playPromise = vjsPlayer?.play()
        if (playPromise !== undefined) {
          playPromise.then((_) => {
            jquery(`#${vjsPlayer.id_}`).focus()
          })
        }
        setTimeout(() => {
          jquery(`#${vjsPlayer.id_}`).focus()
          vjsPlayer?.play()
        }, 1000)
      }
    })
    .catch(onError)
}

function initDrm(players, playerData) {
  // Install built-in polyfills to patch browser incompatibilities.
  shaka.polyfill.installAll()
  vjsPlayer = players
  detail = playerData
  manifestUrl = playerData?.playInfo?.dash_link_play || playerData.linkPlay
  // Check to see if the browser supports the basic APIs Shaka needs.
  if (shaka.Player.isBrowserSupported()) {
    initPlayer()
  } else {
    // This browser does not have the minimum set of APIs we need.
    console.error('Browser not supported!')
  }
}

function initPlayer() {
  const src = vjsPlayer.src
  console.log('manifestUrl', manifestUrl)
  vjsPlayer.src = function (value) {
    if (value !== undefined) {
      src.call(vjsPlayer, value)
    } else {
      return manifestUrl
    }
  }
  video = vjsPlayer.el_.querySelector('video')
  player = new shaka.Player(video)

  if (detail?.drmSession) {
    setupDRMToday()
  }


  vjsPlayer.on('dispose', function () {
    clearInterval(timer)
    const net = player.getNetworkingEngine()
    // Setting up the license response
    net?.clearAllRequestFilters()
    net?.clearAllResponseFilters()
    net?.destroy()
    player?.unload(manifestUrl)
    player?.destroy()
    video = null
    player = null
    vjsPlayer = null
    detail = {}
    timer = null
    manifestUrl = ''
  })

  player.addEventListener('error', onErrorEvent)
}

function onErrorEvent(event) {
  // Extract the shaka.util.Error object from the event.
  onError(event.detail)
}

function onError(error) {
  // Log the error.
  console.error('Error code', error.code, 'object', error)
}

export default initDrm
