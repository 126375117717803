import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import './styles/memoryDebug.scss'

const MemoryDebug = React.memo(() => {
  const webOSTotalMemoryRef = useRef(false)
  const checkMemoryIntervalRef = useRef(null)
  const isEnableRemoteDebugJS = useSelector(state => state.root.isEnableRemoteDebugJS)
  const debugLogKey = localStorage.getItem('remoteDebugLogChannelID')
  const appStartTime = localStorage.getItem('appStartTime')
  
  useEffect(() => {
    const timerInterval = () => {
      const currentDate = new Date()
      const timerEl = document.getElementById('device_current_time')
      if (timerEl) {
        timerEl.innerHTML = currentDate.toLocaleString()
      }
    }

    if (isEnableRemoteDebugJS) {
      timerInterval()

      checkMemoryIntervalRef.current = setInterval(() => {
        const nowTime = new Date().getTime()
        const appOpenTime = (nowTime - appStartTime) / 1000 / 60

        document.getElementById('app_open_time').innerHTML = ` ${appOpenTime.toFixed(0)} mins`

        timerInterval()
        const memoryDevice = window?.performance?.memory
        if (memoryDevice?.jsHeapSizeLimit && document.getElementById('max_device_memory')) {
          document.getElementById('max_device_memory').innerHTML = `${(
            memoryDevice?.jsHeapSizeLimit /
            1024 /
            1024
          ).toFixed(0)} MB`
        }

        if (memoryDevice?.totalJSHeapSize && document.getElementById('allocated_device_memory')) {
          document.getElementById('allocated_device_memory').innerHTML = `${(
            memoryDevice?.totalJSHeapSize /
            1024 /
            1024
          ).toFixed(0)} MB`
        }

        if (memoryDevice?.usedJSHeapSize && document.getElementById('used_device_memory')) {
          document.getElementById('used_device_memory').innerHTML = `${(
            memoryDevice?.usedJSHeapSize /
            1024 /
            1024
          ).toFixed(0)} MB`
        }

        if (window.platform === 'tv_webos' && !webOSTotalMemoryRef.current) {
          window?.webOS?.deviceInfo(device => {
            if (device?.ddrSize && document.getElementById('total_device_memory')) {
              webOSTotalMemoryRef.current = device?.ddrSize
              document.getElementById('total_device_memory').innerHTML = `${device?.ddrSize}`
            }
          })
        }

        if (window.platform === 'tv_tizen') {
          const totalMemory = window?.tizen?.systeminfo?.getTotalMemory()
          const availableMemory = window?.tizen?.systeminfo?.getAvailableMemory()

          if (totalMemory && document.getElementById('total_device_memory')) {
            document.getElementById('total_device_memory').innerHTML = `${(
              totalMemory /
              1024 /
              1024
            ).toFixed(0)} MB`
          }

          if (availableMemory && document.getElementById('available_device_memory')) {
            document.getElementById('available_device_memory').innerHTML = `${(
              availableMemory /
              1024 /
              1024
            ).toFixed(0)} MB`
          }
        }
      }, 5000)
    } else {
      if (checkMemoryIntervalRef.current) {
        clearInterval(checkMemoryIntervalRef.current)
        checkMemoryIntervalRef.current = null
        webOSTotalMemoryRef.current = null
      }
    }

    return () => {
      if (checkMemoryIntervalRef.current) {
        clearInterval(checkMemoryIntervalRef.current)
        checkMemoryIntervalRef.current = null
        webOSTotalMemoryRef.current = null
      }
    }
  }, [isEnableRemoteDebugJS])

  if (isEnableRemoteDebugJS) {
    return (
      <div className='device_memory_wrapper'>
        <div className='device_memory_row'>
          <div className='device_memory_item'>
            <div>Allocated heap memory: </div>
            <div id='allocated_device_memory'></div>
          </div>
          <div className='device_memory_item'>
            <div>Used heap memory: </div>
            <div id='used_device_memory'></div>
          </div>
          <div className='device_memory_item'>
            <div>Heap hard-limits: </div>
            <div id='max_device_memory'></div>
          </div>
        </div>

        <div className='device_memory_row'>
          <div className='device_memory_item'>
            <div>Device RAM size: </div>
            <div id='total_device_memory'></div>
          </div>
          <div className='device_memory_item'>
            <div>Remaining available memory: </div>
            <div id='available_device_memory'></div>
          </div>
        </div>

        <div className='device_memory_row'>
          <div className='device_memory_item'>
            <div>Debug Log Key: </div>
            <div id='device_debug_log_key'>{debugLogKey}</div>
          </div>
          <div className='device_memory_item'>
            <div>Current Time: </div>
            <div id='device_current_time'></div>
          </div>
          <div className='device_memory_item'>
            <div>App Open Time: </div>
            <div id='app_open_time'></div>
          </div>
        </div>
      </div>
    )
  }

  return null
})

export default MemoryDebug
