/* eslint-disable max-len */
import React, { useMemo } from 'react'
import { muxConfig, setInitMux } from './plugins/mux'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getVerisonOs } from 'common/utils'
import Spinner from 'components/Spinner'
import { CONTENT_TYPE_FOR_VOD } from 'constant/content'

import videojs from 'video.js'
import 'videojs-contrib-ads'
import 'videojs-ima'
import 'videojs-mux'
import 'videojs-shaka'
import 'videojs-markers'

import 'features/player/videoComponents/ageRating'
import 'features/player/videoComponents/nextEpisode'
import 'features/player/videoComponents/prevEpisode'
import 'features/player/videoComponents/seekNext'
import 'features/player/videoComponents/seekPrev'
import 'features/player/plugins/liveEvent'
import './styles/video.min.css'

import { useHls } from 'features/player/hooks/useHls'
import useImaAds from 'features/player/hooks/useIma'

const OPTION = {
  loop: false,
  techOrder: ['html5'],
  autoplay: true,
  muted: false,
  controls: true,
  preload: 'manifest',
  bigPlayButton: false,
  inactivityTimeout: 0,
  errorDisplay: false,
  html5: {
    nativeTextTracks: false,
    hlsjsConfig: {
      capLevelToPlayerSize: false,
      enableWorker: window.platform === 'tv_webos' ? false : true,
      startLevel: 0,
      testBandwidth: getVerisonOs().startsWith('3') ? true : false,
      debug: false,
      playlistLoadPolicy: {
        default: {
          maxTimeToFirstByteMs: 10000,
          maxLoadTimeMs: 30000,
          timeoutRetry: {
            maxNumRetry: 200,
            retryDelayMs: 100,
            maxRetryDelayMs: 1000
          },
          errorRetry: {
            maxNumRetry: 200,
            retryDelayMs: 100,
            maxRetryDelayMs: 1000
          }
        }
      },
      manifestLoadPolicy: {
        default: {
          maxTimeToFirstByteMs: 10000,
          maxLoadTimeMs: 30000,
          timeoutRetry: {
            maxNumRetry: 200,
            retryDelayMs: 100,
            maxRetryDelayMs: 1000
          },
          errorRetry: {
            maxNumRetry: 200,
            retryDelayMs: 100,
            maxRetryDelayMs: 1000
          }
        }
      }
    },
    nativeVideoTracks: false
  }
}

export const VideoJS = (props) => {
  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)
  const history = useHistory()

  // eslint-disable-next-line react/prop-types
  const { onReady, player, disposeVis, handleEndVjs } = props
  const playerData = useSelector((state) => state.player.playerData)
  const { account } = useSelector((state) => state.login)
  const content = useSelector((state) => state.content.content)

  const { registerPlugin, initImaAds, isFetchingAds } = useImaAds()

  useHls({ playerData })

  const manifestUrl = useMemo(
    () => playerData?.playInfo?.hls_link_play || playerData.linkPlay,
    [playerData]
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => {
    if (!manifestUrl || isFetchingAds) return
    let linkPlay = manifestUrl

    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return

      let typeVideo = 'application/x-mpegURL'
      if (manifestUrl && manifestUrl?.endsWith('.mp4')) {
        typeVideo = 'video/mp4'
      }

      registerPlugin(videojs)
      // const bandwidth = getVerisonOs().startsWith('4') || getVerisonOs().startsWith('5') ? { bandwidth: 1000000 } : {}
      const optionsCustome = {
        ...OPTION,
        plugins: {
          reloadSourceOnError: {},
          ...(muxConfig(playerData, account) || {})
        },

        html5: {
          ...OPTION.html5,
          // vhs: {
          //   ...OPTION.html5.vhs,
          //   ...bandwidth
          // }
          hlsjsConfig: {
            ...OPTION.html5.hlsjsConfig,
            debug: false
          }
        }
      }

      // videojs.Vhs.GOAL_BUFFER_LENGTH = 20
      // videojs.Vhs.MAX_GOAL_BUFFER_LENGTH = 40
      // videojs.Vhs.BACK_BUFFER_LENGTH = 20

      videojs.log.level('off')
      videojs.log.history.disable()
      const playerEl = videojs(videoElement, optionsCustome, async () => {
        playerEl.src({
          src: manifestUrl,
          type: typeVideo
        })
        playerEl.liveEvent({
          content: content,
          handleClickBack: () => history.goBack()
        })

        await initImaAds(playerEl, content, handleEndVjs)

        const minHeightOfQuality = getVerisonOs().startsWith('5') ? 360 : 540
        let qualityLevels = playerEl?.qualityLevels?.()
        qualityLevels?.on('addqualitylevel', function (event) {
          let qualityLevel = event.qualityLevel

          if (
            getVerisonOs().startsWith('4') ||
            getVerisonOs().startsWith('3')
          ) {
            if (qualityLevel.height <= 720) {
              qualityLevel.enabled = true
            } else {
              qualityLevel.enabled = false
            }
          } else {
            if (qualityLevel.height >= minHeightOfQuality) {
              qualityLevel.enabled = true
            } else {
              qualityLevel.enabled = false
            }
          }
        })
        const controlBar = playerEl.getChild('controlBar')
        if (CONTENT_TYPE_FOR_VOD.includes(playerData.content_type)) {
          controlBar.addChild('Prev_Episode', {})
          controlBar.addChild('Seek_Next', {})
          controlBar.addChild('Seek_Prev', {})
          controlBar.addChild('Next_Episode', {})
        }
        playerRef.current = playerEl
        onReady && onReady(playerEl)
      })
    } else {
      if (!player) return
      const currentSource = player?.currentSource()?.linkPlay
      if (currentSource === linkPlay) return
      player.src(linkPlay)
      setInitMux(playerData, player)
    }
  }, [manifestUrl, isFetchingAds])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (disposeVis) {
        disposeVis()
      }

      if (playerRef.current) {
        const players = window.videojs.players || {}
        for (const property in players) {
          players[property]?.dispose()
          players[`${property}_html5_api`]?.dispose()
        }
        console.log('VideoJS will dispose')
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player>
      {isFetchingAds ? (
        <div className='content-wrapper'>
          <Spinner />
        </div>
      ) : null}
      <video
        id='vimai-sctv-vod-player'
        ref={videoRef}
        className='video-js vjs-default-skin vjs-fill'
      />
    </div>
  )
}

export default VideoJS
