import Hls from 'hls.js'
import videojs from 'video.js'
import useDrmHls from './useDrmHls'

function Html5HlsJS(source, tech, options) {
  const el = tech.el()
  let duration = null
  const hls = new Hls(options)

  const isDebugMode = false

  function disposeHls() {
    console.log('hls destroy')
    hls.destroy()
  }

  function errorHandlerFactory() {
    let _recoverDecodingErrorDate = null
    let _recoverAudioCodecErrorDate = null

    return function () {
      const now = Date.now()

      if (
        !_recoverDecodingErrorDate ||
        now - _recoverDecodingErrorDate > 2000
      ) {
        _recoverDecodingErrorDate = now
        hls.recoverMediaError()
      } else if (
        !_recoverAudioCodecErrorDate ||
        now - _recoverAudioCodecErrorDate > 2000
      ) {
        _recoverAudioCodecErrorDate = now
        hls.swapAudioCodec()
        hls.recoverMediaError()
      } else {
        console.error('Error loading media: File could not be played')
      }
    }
  }

  // create separate error handlers for hlsjs and the video tag
  const hlsjsErrorHandler = errorHandlerFactory()
  const videoTagErrorHandler = errorHandlerFactory()

  // listen to error events coming from the video tag
  el.addEventListener('error', function (e) {
    const mediaError = e.currentTarget.error

    if (mediaError.code === mediaError.MEDIA_ERR_DECODE) {
      videoTagErrorHandler()
    } else {
      console.error('Error loading media: File could not be played')
    }
  })
  // update live status on level load
  hls.on(Hls.Events.LEVEL_LOADED, function (event, data) {
    if (isDebugMode) {
      console.log('LEVEL_LOADED', data)
    }
    duration = data.details.live ? Infinity : data.details.totalduration
  })

  // try to recover on fatal errors
  hls.on(Hls.Events.ERROR, function (event, data) {
    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          hls.startLoad()
          break
        case Hls.ErrorTypes.MEDIA_ERROR:
          hlsjsErrorHandler()
          break
        default:
          console.error('Error loading media: File could not be played')
          break
      }
    }
  })

  Object.keys(Hls.Events).forEach(function (key) {
    const eventName = Hls.Events[key]
    hls.on(eventName, function (event, data) {
      if (isDebugMode) {
        console.log(`${new Date().toLocaleString()} ${eventName}`)
      }
      tech?.trigger(eventName, data)
    })
  })

  hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
    try {
      if (isDebugMode) {
        const startPTS = data?.frag?.elementaryStreams?.video?.startPTS
        const endPTS = data?.frag?.elementaryStreams?.video?.endPTS
        const fragDuration = data?.frag?.duration
        const level = data?.frag?.level
        const sn = data?.frag?.sn
        const loadingStart = data?.frag?.stats?.loading?.start
        const loadingEnd = data?.frag?.stats?.loading?.end
        const fragLoadingTime = (loadingEnd - loadingStart) / 1000
        const bwEstimate = (data?.frag?.stats?.bwEstimate || 0) / 1000000

        console.log(
          `Loaded frag #${sn} level=${level},duration: ${fragDuration?.toFixed(
            2
          )}s, PTS(start=${startPTS?.toFixed(2)},end=${endPTS?.toFixed(
            2
          )}). Loading time=${fragLoadingTime?.toFixed(
            2
          )}s, Frag bwEstimate: ${bwEstimate?.toFixed(2)}Mbps.`
        )
      }
    } catch (e) {
      //
    }
  })

  hls.on(Hls.Events.MANIFEST_LOADED, (event, data) => {
    try {
      if (isDebugMode) {
        // console.log('MANIFEST_LOADED', data)
        const loadingStart = data?.stats?.loading?.start
        const loadingEnd = data?.stats?.loading?.end
        console.log(
          `Manifest loaded time: ${(loadingEnd - loadingStart) / 1000}`
        )
        console.log(`Levels list ${data?.levels?.length}`)
        data?.levels?.map((level) => {
          console.log(
            `- Resolution: ${level?.attrs?.RESOLUTION}, bitrate: ${
              level?.bitrate / 1000000
            } Mbps`
          )
        })
      }
    } catch (e) {
      //
    }
  })

  if (!tech.featuresNativeTextTracks) {
    Object.defineProperty(el, 'textTracks', {
      value: tech.textTracks,
      writable: false
    })
    el.addTextTrack = function () {
      return tech.addTextTrack.apply(tech, arguments)
    }
  }

  hls.attachMedia(el)
  hls.loadSource(source.src)
  return {
    dispose: () => disposeHls(),
    duration: () => {
      return duration || el.duration || 0
    },
    hls: () => hls
  }
}

export const useHls = ({ playerData }) => {
  const { configHlsDrm } = useDrmHls()
  const hlsTypeRE = /^application\/(x-mpegURL|vnd\.apple\.mpegURL)$/i
  const hlsExtRE = /\.m3u8/i

  const hlsSourceHandler = {
    canHandleSource: function (source) {
      if (source.skipContribHlsJs) {
        return ''
      } else if (hlsTypeRE.test(source.type)) {
        return 'probably'
      } else if (hlsExtRE.test(source.src)) {
        return 'maybe'
      } else {
        return ''
      }
    },
    handleSource: (source, tech) => {
      const drmConfig = configHlsDrm({ playerData })
      const statusRemoteDebugJS = localStorage.getItem('statusRemoteDebugJS')
      const optionVideojs = tech.options_.hlsjsConfig
      const options = {
        //...optionVideojs,
        debug: statusRemoteDebugJS === 'enable' ? true : false,
        maxBufferLength: 60,
        maxBufferSize: 150 * 1024 * 1024,
        enableWorker: true,
        ...drmConfig
    }
      return new Html5HlsJS(source, tech, options)
    },
    canPlayType: function (type) {
      if (hlsTypeRE.test(type)) {
        return 'probably'
      }
      return ''
    }
  }
  if (playerData?.drmSession?.merchant === 'qnet') return;

  var html5Tech = videojs.getTech && videojs.getTech('Html5') // videojs6 (partially on videojs5 too)
  html5Tech =
    html5Tech || (videojs.getComponent && videojs.getComponent('Html5')) // videojs5

  if (html5Tech) {
    html5Tech.registerSourceHandler(hlsSourceHandler, 0)
  }

  return {}
}
