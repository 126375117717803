import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { getDataTenant } from 'common/utils'
import { setIsShowLogin, setLoginAccount } from 'features/home/loginSlice'

const useProfile = () => {
    const dispatch = useDispatch()

    const getProfile = useMutation(
        () => getDataTenant('GET', 'accounts/profile'),
        {
            onSuccess: async (loginResponse) => {
                return new Promise((resolve) => {
                    const profile = localStorage.getItem('user_profile')
                    const userProfile = JSON.parse(profile)
                    if (!userProfile) return

                    const accountInfo = {
                        ...userProfile,
                        profile: {
                            ...loginResponse
                        }
                    }

                    dispatch(setLoginAccount(accountInfo))
                    localStorage.setItem('user_profile', JSON.stringify(accountInfo))
                    
                    resolve()
                })
            },
            onError: async (err) => {
                console.log('request profile error', err)
                await handleLogoutApi()
                localStorage.removeItem('user_profile')
                dispatch(setLoginAccount())
                dispatch(setIsShowLogin(false))
            }
        }
    )

    const { mutateAsync: handleLogoutApi } = useMutation(
        async () => await getDataTenant('POST', 'accounts/logout'),
    )

    const handleLogout = async (action = 'auto') => {
        if (await localStorage.getItem('user_profile')) {
            if (action === 'click') {
                await handleLogoutApi()
            }
            await localStorage.removeItem('user_profile')
        }
        dispatch(setLoginAccount())
        dispatch(setIsShowLogin(false))

    }

    return {
        getProfile,
        handleLogout
    }
}

export default useProfile
