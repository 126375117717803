import { useEffect, useRef } from 'react'
import { disableRemoteLogging, enableRemoteLogging, addRemoteLogging } from 'common/remoteLogging'
import { useSelector, useDispatch } from 'react-redux'

import { setStatusRemoteDebugJS } from 'common/slice'
interface RootState {
  root: {
    [key: string]: any
  }
}

export const useDebugJS = () => {
  const dispatch = useDispatch()
  const isEnableRemoteDebugJS = useSelector((state: RootState) => state.root.isEnableRemoteDebugJS)

  const debugKeyClickCountRef = useRef(0)
  const isDebug = useRef(false)

  const setValueRemoteDebug = (value: string) => {
    localStorage.setItem("statusRemoteDebugJS", value);
  }

  const handleEnableJS = (number: number) => {
    debugKeyClickCountRef.current++
    if (debugKeyClickCountRef.current >= number) {
      debugKeyClickCountRef.current = 0
      dispatch(setStatusRemoteDebugJS(true))
      setValueRemoteDebug('enable')
      if (isDebug.current === true) return enableRemoteLogging()
      isDebug.current = true
      addRemoteLogging(null)
      window.location.reload()
    }
  }

  const handleDisableJS = (number: number) => {
    debugKeyClickCountRef.current++
    if (debugKeyClickCountRef.current >= number) {
      debugKeyClickCountRef.current = 0
      if (isEnableRemoteDebugJS) {
        dispatch(setStatusRemoteDebugJS(false))
        setValueRemoteDebug('disable')
        disableRemoteLogging()
        window.location.reload()
      }
    }
  }

  const resetCount = () => {
    debugKeyClickCountRef.current = 0
  }

  useEffect(() => {
    if (isEnableRemoteDebugJS) {
      isDebug.current = true
    }
    const statusRemoteDebugJS = localStorage.getItem('statusRemoteDebugJS')
    if (statusRemoteDebugJS !== '') {
      dispatch(setStatusRemoteDebugJS(statusRemoteDebugJS === 'enable' ? true : false))
      if (statusRemoteDebugJS === 'enable') {
        addRemoteLogging(null)
      }
    }
  }, [isEnableRemoteDebugJS])

  return {
    handleEnableJS,
    handleDisableJS,
    resetCount,

    isEnableRemoteDebugJS
  }
}
