/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react'
import { keyCode, formatDate, enabledPreview } from 'common/utils'
import { useDispatch, useSelector } from 'react-redux'
import { setIsShowLogin } from 'features/home/loginSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import jquery from 'jquery'
import useProfile from 'hooks/useProfile'
import Spinner from 'components/Spinner'

import { toggleOpenMenu, toggleNavigateMenu, toggleNavigateRibbon } from '../../../common/slice'
import { useDebugJS } from 'hooks/useDebugJs'

import '../styles/profileModal.scss'

const ProfileInfo = ({ account, handleClickLogout, messageFakeEmail, active }) => {
  if (!account) return null

  const { phone_number, email, first_name } = account?.profile

  const info = [phone_number, email, first_name]
  const inforTxt = ['Số điện thoại', 'Email', 'Họ Tên']
  return (
    <div className="profile-info-left">
      <div className="profile-info-left-container">
        <div className="mb-8 profile-title">Tài Khoản</div>
        <div className="profile-sub-description-info">Để thay đổi thông tin tài khoản, vui lòng truy cập và đăng nhập website https://sctvonline.vn</div>
        <div className="mb-8 profile-body">
            {info.map((items, index) => {
              if (items) {
                return (
                  <div className="mb-3" key={`info-${index}`}>
                    <label className="profile-label">{inforTxt[index]}</label>
                      <div className='profile-value'>{items}</div>
                      {messageFakeEmail !== '' && index === 1
                        ? (
                          <div className="profile-fake-email-info">{messageFakeEmail}</div>
                          )
                        : null}
                  </div>
                )
              }
              return null
            })}
        </div>

        <button
          onClick={() => handleClickLogout()}
          className={`btn profile-logout-btn profile-btn ${active.field === 'logoutBtn' ? 'active' : ''}`}
        >
          Đăng Xuất
        </button>
      </div>
    </div>
  )
}

const Service = ({ account, isHasSubscription }) => {
  return (
    <div className="profile-info-right">
      {isHasSubscription
        ? (
            <div className="mb-8 profile-body service-package  profile-info-right-container">
              <label className="form-label label profile-label">Gói dịch vụ của bạn</label>
              <div className='profile-value'>{account?.profile?.subscription_plan_info?.name}</div>

              <div className="profile-sub-description">
                {account?.profile?.subscription_plan_info?.is_auto_renew
                  ? 'Tự động gia hạn vào ngày'
                  : 'Sử dụng đến ngày'
                }
                {' '}
                {formatDate(account?.profile?.subscription_plan_info?.expired_time, 'dd/mm/yyyy')}
              </div>
            <div className="profile-sub-description-info">
              Để thay đổi gói dịch vụ của bạn, vui lòng truy cập và đăng nhập website <br /><span className="profile-modal-highlight-red">https://sctvonline.vn</span> hoặc ứng dụng <span className="profile-modal-highlight-red">SCTVOnline</span> trên Android hoặc iOS
            </div>
            </div>
          )
        : (
          <div className="mb-8 profile-body service-package  profile-info-right-container">
              <div className="mb-3">
                <label className="form-label label profile-label">Gói dịch vụ của bạn</label>
                <div className='profile-value'>SCTVOnline Basic</div>

                <div className="profile-sub-none-info">Truy cập ngay website <span className="profile-modal-highlight-red">https://sctvonline.vn</span> hoặc ứng dụng <span className="profile-modal-highlight-red">SCTVOnline</span> trên Android <br />hoặc iOS để đăng ký gói dịch vụ <span className="profile-modal-highlight-yellow">SCTVOnline PREMIUM</span>.</div>
              </div>
            </div>
          )
      }
    </div>
  )
}

const ProfileModal = (props) => {
  const dispatch = useDispatch()
  const isShowLogin = useSelector((state) => state.login.isShowLogin)
  const account = useSelector((state) => state.login.account)
  const currentMenu = useSelector((state) => state.menu.currentMenu)
  const isLogin = useSelector((state) => state.login.isLogin)
  const { handleEnableJS, handleDisableJS, resetCount, isEnableRemoteDebugJS } = useDebugJS()

  const { getProfile, handleLogout } = useProfile()

  const [active, setActive] = useState({
    field: 'backBtn'
  })

  const [isHasSubscription, setIsHasSubscription] = useState(false)
  const [messageFakeEmail, setMessageFakeEmail] = useState('')
  const [isLogout, setLogout] = useState(false)
  const [numbCount, setCount] = useState(0)
  const eventListener = useCallback(() => {
  }, [active?.field, isShowLogin, isLogin, numbCount, isEnableRemoteDebugJS])

  useEffect(() => {
    if (currentMenu?.slug === 'login' && isLogin) getProfile?.mutateAsync()
  }, [currentMenu?.slug])
  
  useEffect(async () => {
    if (!isLogout || getProfile.isLoading) return
    handleLogout('click')
    setLogout(false)
  },[isLogout, getProfile.isLoading])

  useEffect(() => {
    if (account) {
      setIsHasSubscription(account.profile.has_subscription)

      const email = account.profile.origin_email || ''
      let message = ''
      if (email.includes('@unknown.email')) {
        message = 'Đây là email được tạo tự động. Bạn có thể thay đổi lại email thật của mình.'
      }
      setMessageFakeEmail(message)
    }

    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  useEffect(() => {
    if (numbCount === 10) {
      if (localStorage.getItem('xPreviewMode')) {
        localStorage.removeItem('xPreviewMode')
      } else {
        enabledPreview()
      }

      const toastElList = jquery('.toast')
      toastElList.addClass('show')
      toastElList.removeClass('hide')

      setTimeout(() => {
        toastElList.addClass('hide')
        toastElList.removeClass('show')
      }, 3000)
    }
  }, [numbCount])


  useEffect(() => {
    return () => {
      resetCount()
    }
  }, [numbCount])


  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()
    if (isShowLogin) {
      switch (e.keyCode) {
        case keyCode.RETURN: {
          handleClickBack()
          break
        }
        case keyCode.ENTER: {
          if (active.field === 'logoutBtn') {
            handleClickLogout()
            return
          }
          handleClickBack()

          break
        }
        case keyCode.RIGHT: {
          if (numbCount < 10) {
            setCount(numbCount + 1)
          }
          if (isEnableRemoteDebugJS) {
            handleDisableJS(10)
            return
          }
          handleEnableJS(10)
          break
        }
        case keyCode.LEFT: {
          if (numbCount < 10) {
            setCount(numbCount + 1)
          }
          break
        }
        case keyCode.DOWN: {
          if (active.field === 'backBtn') {
            setActive({
              field: 'logoutBtn'
            })
          }
          break
        }
        case keyCode.UP: {
          if (active.field === 'logoutBtn') {
            setActive({
              field: 'backBtn'
            })
          }
          break
        }
      }
    }
  }

  const handleClickLogout = () => {
    setLogout(true)
  }

  if (!account) return null

  const handleClickBack = () => {
    dispatch(toggleOpenMenu())
    dispatch(toggleNavigateMenu())
    dispatch(toggleNavigateRibbon())
    dispatch(setIsShowLogin(false))
  }
  if (isLogout && getProfile.isLoading) {
    return (
      <div className="content-wrapper content-detail-loading">
        <Spinner />
      </div>
    )
  }


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col col-md-12">
          <div id="profile-back-button" className="profile-back-button">
            <div id='profile-back-button-wrapper' className={`profile-back-button-wrapper ${active.field === 'backBtn' ? 'active' : ''}`}>
              <FontAwesomeIcon
                className='profile-back-icon'
                icon={faChevronLeft}
                onClick={() => handleClickBack()}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row profile-container">
        <ProfileInfo account={account} messageFakeEmail={messageFakeEmail} handleClickLogout={handleClickLogout} active={active} />
        <Service account={account} isHasSubscription={isHasSubscription} />
      </div>

      <div className="profile-modal-back-instruction">
        Bấm &nbsp;<FontAwesomeIcon className="content-button-shadow" icon={faArrowLeft} /> Back &nbsp;trên điều khiển để trở về
      </div>
      <div className="position-fixed bottom-0 end-0 p-3" style={{ width: '100%' }}>
        <div id="liveToast" className="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-body">
            Enable preview mode
          </div>
        </div>
      </div>

    </div>
  )
}

export default ProfileModal
