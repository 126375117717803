/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef, forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { keyCode, getData, formatDate, formatTime, scrollVertical } from 'common/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faCircle } from '@fortawesome/free-solid-svg-icons'
import Modal from 'bootstrap/js/dist/modal'
import Toast from 'bootstrap/js/dist/toast'
import { setIsShowEpg, setPlayerData } from 'features/player/playerSlice'
import { setIsRewatchEpg, setChannelId, setLinkPlay } from 'features/epg/epgSlice'
import Spinner from 'components/Spinner'
import { getContentLivePremiere } from 'core/method/content'

import '../styles/epg.scss'
// import { imgholder } from 'constant/images'

const Epg = forwardRef((props, ref) => {
  const {
    channelId,
    title,
    setIsBlackOut,
    isRewatch,
    player,
    content
  } = props

  const toastRef = useRef()
  const timeoutAutoNextEPGRef = useRef(null)
  const currentTime = new Date().getTime()
  const currentEpgPlayingIdxIdxRef = useRef(null)

  const history = useHistory()
  const dispatch = useDispatch()
  const isShowEpg = useSelector((state) => state.player.isShowEpg)
  const isRewatchEpg = useSelector((state) => state.epg.isRewatchEpg)
  const playerData = useSelector((state) => state.player.playerData)
  const playerDataLive = useSelector((state) => state.player.playerDataLive)

  const [toast, setToast] = useState(null)
  const [modal, setModal] = useState(null)
  const [data, setData] = useState([])
  const [scheduleList, setScheduleList] = useState([])
  const [schedule, setSchedule] = useState(null)
  const [active, setActive] = useState({
    category: 'schedule',
    item: 6
  })
  const [currentSchedule, setCurrentSchedule] = useState(0)
  const [currentEpg, setCurrentEpg] = useState(0)
  const [currentEpgPlayingIdx, setCurrentEpgPlayingIdx] = useState(-1)
  const isLivePremiere = useMemo(() => getContentLivePremiere.call(content),[content])

  const { data: dataEpg, isLoading } = useQuery(['getEpg', channelId, schedule], async () => {
    if (channelId && schedule) {
      const nowTime = Math.floor(Date.now() / 1000)
      return await getData('GET', `epg/?channel_id=${channelId}&schedule_date=${schedule.value}&now=${nowTime}`)
    }
  })

  const eventListener = useCallback(() => {
  }, [data, currentSchedule, currentEpg, scheduleList, active, modal, isShowEpg, toast, playerDataLive, currentEpgPlayingIdx])

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  useEffect(() => {
    window.onpopstate = () => {
      dispatch(setIsShowEpg(false))
    }

    setToast(new Toast(toastRef.current))

    const currentDate = new Date()
    const dateListTemp = []

    for (let i = 6; i > -2; i--) {
      const day = currentDate - (86400000 * i)
      dateListTemp.push({
        value: formatDate(day, 'yyyy-mm-dd'),
        label: formatDate(day, 'dd/mm/yyyy')
      })
    }

    setScheduleList(dateListTemp)

    return () => {
      if (timeoutAutoNextEPGRef.current) {
        clearTimeout(timeoutAutoNextEPGRef.current)
        timeoutAutoNextEPGRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (ref && ref.current) {
      setModal(new Modal(ref.current, {
        backdrop: 'static'
      }))
    }
  }, [ref])

  useEffect(() => {
    if (modal) {
      ref.current.disposeModal = () => {
        if (modal._element) {
          modal?.dispose()
        }
      }
    }

    return () => {
      if (modal && modal._element) {
        modal?.dispose()
      }
    }
  }, [modal])

  useEffect(() => {
    return () => {
      if (toast && toast._element) {
        toast.dispose()
      }
    }
  }, [toast])

  useEffect(() => {
    if (player) {
      player?.on('ended', () => {
        if (data?.length - 1 >= currentEpgPlayingIdxIdxRef.current && currentEpgPlayingIdxIdxRef.current !== -1) {
          const nextActiveEPGidx = currentEpgPlayingIdxIdxRef.current + 1
          const nextEPGData = data[nextActiveEPGidx]

          if (nextEPGData?.is_blackout === 1) {
            setIsBlackOut(true)
          } else {
            setIsBlackOut(false)
          }

          setCurrentEpg(() => nextActiveEPGidx)
          setCurrentEpgPlayingIdx(() => nextActiveEPGidx)
        } else {
          if (timeoutAutoNextEPGRef.current) {
            clearTimeout(timeoutAutoNextEPGRef.current)
            timeoutAutoNextEPGRef.current = null
          }

          history.goBack()
        }
      })
    }
  }, [player])

  useEffect(() => {
    if (isShowEpg) {
      if (modal && modal._element) {
        modal.show()
      }

      if (currentSchedule === 6 || (active.category === 'schedule' && active.item === 6)) {
        for (let i = 0; i < data.length; i++) {
          if (currentTime > (data[i].start_at * 1000) && currentTime < (data[i].end_at * 1000)) {
            setTimeout(() => {
              scrollVertical('container-epg', `epg-${i}`, 'DOWN')
              setCurrentEpg(i)
            }, 500)
          }
        }
      }
    } else {
      if (modal && modal._element) {
        modal.hide()
      }
    }
  }, [isShowEpg])

  useEffect(() => {
    if (dataEpg) {
      dispatch(setChannelId(channelId))
      setData(dataEpg?.items?.slice())

      // const start = 1688367694
      // const end = 1688368094

      // const tempEpgs = []
      // dataEpg.items?.map((item, idx) => {
      //   if (idx === 0) {
      //     tempEpgs.push({
      //       ...item,
      //       start_at: start.toString(),
      //       end_at: end.toString(),
      //       is_blackout: 1
      //     })
      //   }

      //   if (idx === 0) {
      //     tempEpgs.push({
      //       ...item,
      //       start_at: (start + 600).toString(),
      //       end_at: (end + 700).toString(),
      //       is_blackout: 0
      //     })
      //   }
      // })

      // tempEpgs.push({
      //   ...tempEpgs[1],
      //   start_at: (start + 700).toString(),
      //   end_at: (end + 800).toString(),
      //   is_blackout: 1
      // })

      // setData(tempEpgs?.slice())
    }
  }, [dataEpg])

  useEffect(() => {
    if (data?.length > 0 && currentEpgPlayingIdx !== -1) {
      currentEpgPlayingIdxIdxRef.current = currentEpgPlayingIdx
      const currentEPGData = data[currentEpgPlayingIdxIdxRef.current]
      // console.log('currentEpgPlayingIdx', currentEpgPlayingIdx)
      // console.log('currentEPGData', currentEPGData)

      if (currentEPGData?.is_blackout === 1) {
        setIsBlackOut(true)
      } else {
        setIsBlackOut(false)
      }

      if (!isRewatch) {
        setTimeout(() => {
          handleAutoNextEPG()
        }, 2000)
      }
    }
  }, [currentEpgPlayingIdx, data])

  const handleAutoNextEPG = () => {
    if (timeoutAutoNextEPGRef.current) {
      clearTimeout(timeoutAutoNextEPGRef.current)
      timeoutAutoNextEPGRef.current = null
    }

    if (data?.length - 1 >= currentEpgPlayingIdxIdxRef.current && currentEpgPlayingIdxIdxRef.current !== -1) {
      timeoutAutoNextEPGRef.current = setInterval(() => {

        const currentEPGData = data[currentEpgPlayingIdxIdxRef.current]
        const nowtime = (new Date().getTime()) / 1000
        const autoNextTime = currentEPGData?.end_at - nowtime
        
        // console.log('autoNextTime', autoNextTime)

        if (autoNextTime < 0) {
          if (data?.length - 1 > currentEpgPlayingIdxIdxRef.current) {
            const nextActiveEPGidx = currentEpgPlayingIdxIdxRef.current + 1
            const nextEPGData = data[nextActiveEPGidx]

            // console.log('nextActiveEPGidx', nextActiveEPGidx)
            // console.log('nextEPGData', nextEPGData)

            if (nextEPGData?.is_blackout === 1) {
              setIsBlackOut(true)
            } else {
              setIsBlackOut(false)
            }

            setCurrentEpg(() => nextActiveEPGidx)
            setCurrentEpgPlayingIdx(() => nextActiveEPGidx)
          } else {
            if (timeoutAutoNextEPGRef.current) {
              clearTimeout(timeoutAutoNextEPGRef.current)
              timeoutAutoNextEPGRef.current = null
            }
            history.goBack()
          }
        }
      }, 1000)
    }
  }

  // auto scroll khi đang live
  useEffect(() => {
    if (currentSchedule === 6 || (active.category === 'schedule' && active.item === 6)) {
      for (let i = 0; i < data.length; i++) {
        if (currentTime > (data[i].start_at * 1000) && currentTime < (data[i].end_at * 1000)) {
          setTimeout(() => {
            scrollVertical('container-epg', `epg-${i}`, 'DOWN')
            setCurrentEpg(i)
            setCurrentEpgPlayingIdx(i)
          }, 500)
        }
      }
    } else {
      setTimeout(() => {
        scrollVertical('container-epg', 'container-epg', 'UP')
      }, 500)
    }
  }, [data])

  useEffect(() => {
    setCurrentSchedule(6)
    scrollVertical('container-schedule', 'schedule-title-6', 'DOWN')
  }, [scheduleList])

  useEffect(() => {
    setSchedule(scheduleList[currentSchedule])
  }, [currentSchedule])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()

    switch (e.keyCode) {
      case keyCode.RETURN: {
        if (isShowEpg) {
          dispatch(setIsShowEpg(false))
        }
        break
      }
      case keyCode.EXIT: {
        if (isShowEpg) {
          dispatch(setIsShowEpg(false))
        }
        break
      }
      case keyCode.ENTER: {
        if (isShowEpg) {
          if (active.category === 'back') {
            dispatch(setIsShowEpg(false))
          }

          if (active.category === 'epg') {
            if (timeoutAutoNextEPGRef.current) {
              clearTimeout(timeoutAutoNextEPGRef.current)
              timeoutAutoNextEPGRef.current = null
            }

            const dataEpgSec = data[active.item]

            if (dataEpgSec?.is_blackout === 1) {
              return
            }

            if (!dataEpgSec?.link_play) {
              dispatch(setIsShowEpg(false))
              return
            }

            setCurrentEpgPlayingIdx(active.item)

            if (currentSchedule === 6) {
              if (currentTime < (dataEpgSec.start_at * 1000)) {
                if (toast) {
                  toast.show()
                }
                return
              }

              if (currentTime >= (dataEpgSec.start_at * 1000) && currentTime < (dataEpgSec.end_at * 1000)) {
                dispatch(setPlayerData({
                  ...playerData,
                  id: dataEpgSec?.id,
                  title: dataEpgSec?.title,
                  views: dataEpgSec?.views,
                  linkPlay: playerDataLive?.link_play,
                  content_type: dataEpgSec?.type,
                  content_title: '',
                  playInfo: playerDataLive?.playInfo,
                }))
                dispatch(setIsShowEpg(false))
                dispatch(setIsRewatchEpg(false))
                history.replace('/playerlive')
                return
              }

              dispatch(setPlayerData({
                ...playerData,
                id: dataEpgSec?.id,
                title: dataEpgSec?.title,
                views: dataEpgSec?.views,
                linkPlay: dataEpgSec?.link_play,
                content_title: '',
                playInfo: null,
              }))
              dispatch(setIsShowEpg(false))
              dispatch(setIsRewatchEpg(true))

              if (isRewatchEpg) {
                dispatch(setLinkPlay(dataEpgSec?.link_play))
              } else {
                history.replace('/playerepg')
              }
            } else if (currentSchedule > 6) {
              if (toast) {
                toast.show()
              }
            } else {
              dispatch(setPlayerData({
                ...playerData,
                id: dataEpgSec?.id,
                title: dataEpgSec?.title,
                views: dataEpgSec?.views,
                linkPlay: dataEpgSec?.link_play,
                content_title: '',
                playInfo: null,
              }))
              dispatch(setIsShowEpg(false))
              dispatch(setIsRewatchEpg(true))
              if (isRewatchEpg) {
                dispatch(setLinkPlay(dataEpgSec?.link_play))
              } else {
                history.replace('/playerepg')
              }
            }
          }
        }
        break
      }
      // Từ schedule --> nút Back
      case keyCode.UP: {
        if (isShowEpg) {
          if (active.category === 'schedule') {
            if (active.item === 0) {
              setActive({
                category: 'back',
                item: 0
              })
              setCurrentSchedule(0)
            } else {
              if (currentSchedule === 7) { // 7 - 1 = 6 --> đang live

              } else {
                setCurrentEpg(0)
              }

              scrollVertical('container-schedule', `schedule-title-${active.item - 1}`, 'UP')
              setActive({
                category: 'schedule',
                item: active.item - 1
              })
              setCurrentSchedule(active.item - 1)
            }
          }
          if (active.category === 'epg') {
            if (active.item === 0) {
              setCurrentEpg(0)
            } else {
              if (active.item === 1) {
                scrollVertical('container-epg', 'container-epg', 'UP')
              } else {
                scrollVertical('container-epg', `epg-${active.item - 1}`, 'UP')
              }
              setActive({
                category: 'epg',
                item: active.item - 1
              })
              setCurrentEpg(active.item - 1)
            }
          }
        }
        break
      }
      // Từ nút Back --> schedule
      // Navigate schedule
      // Navigate epg
      case keyCode.DOWN: {
        if (isShowEpg) {
          if (active.category === 'back') {
            setActive({
              category: 'schedule',
              item: 0
            })
            setCurrentSchedule(0)
            setCurrentEpg(0)
          }
          if (active.category === 'schedule') {
            if (active.item < (scheduleList.length - 1)) {
              if (currentSchedule === 5) { // 5 + 1 = 6 --> đang live

              } else {
                setCurrentEpg(0)
              }

              scrollVertical('container-schedule', `schedule-title-${active.item + 1}`, 'DOWN')
              setActive({
                category: 'schedule',
                item: active.item + 1
              })
              setCurrentSchedule(active.item + 1)
            }
          }
          if (active.category === 'epg') {
            if (active.item < (data.length - 1)) {
              scrollVertical('container-epg', `epg-${active.item + 1}`, 'DOWN')
              setActive({
                category: 'epg',
                item: active.item + 1
              })
              setCurrentEpg(active.item + 1)
            }
          }
        }
        break
      }
      // Từ schedule --> epg
      case keyCode.LEFT: {
        if (isShowEpg) {
          if (active.category === 'epg') {
            setActive({
              category: 'schedule',
              item: currentSchedule
            })
          }
        }
        break
      }
      // Từ epg --> schedule
      case keyCode.RIGHT: {
        if (isShowEpg) {
          if (active.category === 'schedule') {
            if (data && data.length > 0) {
              setActive({
                category: 'epg',
                item: currentEpg
              })
            }
          }
        }
        break
      }
    }
  }

  const handleClickBack = () => {
    dispatch(setIsShowEpg(false))
  }

  const handleClickSchedule = (e, index) => {
    setActive({
      category: 'schedule',
      item: index
    })
    setCurrentSchedule(index)
  }

  const handleClickEpg = (e, index) => {
    if (timeoutAutoNextEPGRef.current) {
      clearTimeout(timeoutAutoNextEPGRef.current)
      timeoutAutoNextEPGRef.current = null
    }

    const dataEpgSec = data[index]

    if (dataEpgSec?.is_blackout === 1) {
      return
    }

    if (!dataEpgSec?.link_play) {
      dispatch(setIsShowEpg(false))
      return
    }

    setCurrentEpgPlayingIdx(index)

    if (currentSchedule === 6) {
      if (currentTime < (dataEpgSec.start_at * 1000)) {
        if (toast) {
          toast.show()
        }
      } else {
        dispatch(setPlayerData({
          ...playerData,
          id: dataEpgSec?.id,
          title: dataEpgSec?.title,
          views: dataEpgSec?.views,
          linkPlay: dataEpgSec?.link_play,
          content_type: dataEpgSec?.type,
          content_title: '',
          playInfo: null,
        }))
        dispatch(setIsShowEpg(false))
        dispatch(setIsRewatchEpg(true))

        if (isRewatchEpg) {
          dispatch(setLinkPlay(dataEpgSec?.link_play))
        } else {
          dispatch(setLinkPlay(dataEpgSec?.link_play))
          history.replace('/playerepg')
        }
      }
    } else if (currentSchedule > 6) {
      if (toast) {
        toast.show()
      }
    } else {
      dispatch(setPlayerData({
        ...playerData,
        id: dataEpgSec.id,
        title: dataEpgSec.title,
        views: dataEpgSec.views,
        linkPlay: dataEpgSec?.link_play,
        content_type: dataEpgSec?.type,
        content_title: '',
        playInfo: null,
      }))
      dispatch(setIsShowEpg(false))
      dispatch(setIsRewatchEpg(true))

      if (isRewatchEpg) {
        dispatch(setLinkPlay(dataEpgSec?.link_play))
      } else {
        history.replace('/playerepg')
      }
    }
  }

  return (
    <div ref={ref} className="modal" tabIndex="-1">
      <div className="modal-dialog modal-fullscreen">
        <div id="modal-content" className="modal-content">
          <div id="modal-body" className="modal-body modal-epg-background">
            <div ref={toastRef} className="toast align-items-center text-white" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="d-flex">
                <div className="toast-body">
                  Chương trình chưa phát sóng
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col col-md-12 epg-title">
                <div className='epg-modal-back-button'>
                  <FontAwesomeIcon
                    className={`epg-back-icon ${active.category === 'back' ? 'active' : ''}`}
                    icon={faChevronLeft}
                    onClick={handleClickBack}
                  />
                  {title}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div id="container-schedule" className="col col-md-3 container-schedule">
                {scheduleList && scheduleList.map((item, index) => {
                  return (
                      <div
                        key={index}
                        id={`schedule-title-${index}`}
                        className={`alert alert-primary ${(active.category === 'schedule' && active.item === index) ? 'active' : ''} ${scheduleList[currentSchedule].label === item.label ? 'focus' : ''}`} role="alert"
                        onClick={e => handleClickSchedule(e, index)}
                      >
                        {item.label}
                        {index === 6  &&
                          <span className="float-end epg-live-text">
                            <FontAwesomeIcon className="epg-live-icon" icon={faCircle} />
                            {isLivePremiere ? 'PREMIERE' : 'Live'}
                          </span>
                        }
                      </div>
                  )
                })}
              </div>

              {isLoading &&
                <Spinner />
              }

              {!isLoading &&
                <div className="col col-md-7 container-epg">
                  <p className="epg-schedule-title">
                    {currentSchedule === 5
                      ? 'Hôm qua'
                      : currentSchedule === 6
                        ? 'Hôm nay'
                        : currentSchedule === 7
                          ? 'Ngày mai'
                          : scheduleList[currentSchedule] && scheduleList[currentSchedule].label}
                  </p>
                  <div id="container-epg" className="col col-md-12 container-epg-scroll">
                    {data && data.map((item, index) => {
                      return (
                        <div
                          key={index}
                          id={`epg-${index}`}
                          className={`row epg ${(active.category === 'epg' && active.item === index) ? 'active' : ''}`}
                          onClick={e => handleClickEpg(e, index)}
                        >
                          <div className="col col-md-12 epg-info">
                            <div className='epg-info-item-row'>
                              <p className="epg-info-time">{formatTime(item.start_at)}</p>

                              <div className='epg-info-title-group'>
                                {currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000) &&
                                  <p className="epg-info-favorites">
                                    <FontAwesomeIcon className="epg-live-icon" icon={faCircle} />
                                  </p>
                                }

                                <p className={`epg-info-title ${item?.is_blackout === 1 ? 'blackout' : ''}`}>{item.title}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className='fix-epg-scroll-to-bottom' />
                  </div>
                </div>
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

Epg.propTypes = {
  channelId: PropTypes.string,
  title: PropTypes.string
}

Epg.defaultProps = {
  channelId: '',
  title: ''
}

Epg.displayName = 'Epg'

export default Epg
