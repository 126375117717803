import Hls from 'hls.js'

const useDrmHls = () => {
  const configHlsDrm = ({ playerData }) => {
    if (!playerData.drmSession) return {}
    const getWidevineCert = (xhr, url, keyContext, licenseChallenge) => {
      const { user_id, session, merchant, auth_token } = playerData.drmSession
      xhr.open('POST', url)

      let params = {
        userId: user_id,
        sessionId: session,
        merchant: merchant || 'expressplay'
      }

      params = btoa(JSON.stringify(params))

      xhr.setRequestHeader('dt-custom-data', params)
      xhr.setRequestHeader('x-dt-custom-data', params)
      xhr.setRequestHeader('response-type', 'arraybuffer')
      if (auth_token) {
        xhr.setRequestHeader('x-dt-auth-token', auth_token)
      }
      xhr.addEventListener('error', function (ev) {
        console.log('error', ev)
      })

      xhr.addEventListener('load', function (ev) {
        console.log('data', ev)
      })
      // return licenseChallenge
    }

    const setWidevineDataResponse = async (xhr, url, keyContext) => {
      const response = xhr.response
      const data = handleDataFromGetLicense(response)
      return data
    }

    return {
      emeEnabled: true,
      drmSystemOptions: {
        audioRobustness: 'SW_SECURE_CRYPTO',
        videoRobustness: 'SW_SECURE_CRYPTO'
      },
      drmSystems: {
        'com.widevine.alpha': {
          licenseUrl: playerData.drmSession.widevide_license_path,
          /*generateRequest: (initDataType, initData, keyContext) => {
            // Only generate sessions for playlist KEY-URIs.
            // Change filter to 'ISO-23001-7' to only allow "encrypted" event initData.
            console.log('hls.js eme generateRequest')
            return { initDataType, initData }
          }*/
        }
      },

      requestMediaKeySystemAccessFunc: (keySystem, supportedConfigurations) => {
        return navigator.requestMediaKeySystemAccess(
          keySystem,
          supportedConfigurations
        )
      },
      licenseXhrSetup: function (xhr, url, keyContext, licenseChallenge) {
        return getWidevineCert(xhr, url, keyContext, licenseChallenge)
      },
      licenseResponseCallback: function (xhr, url, keyContext) {
        return setWidevineDataResponse(xhr, url, keyContext)
      }
    }
  }


  const handleDataFromGetLicense = (responseBody) => {
    try {
      let wrappedArray = new Uint8Array(responseBody)
      // Convert it to a string.
      let wrappedString = String.fromCharCode.apply(null, wrappedArray)
      // Parse the JSON string into an object.
      let wrapped
      wrapped = JSON.parse(wrappedString)
      // This is a base64-encoded version of the raw license.
      let rawLicenseBase64 = wrapped['license']
      // Decode it to a string.
      let rawLicenseString = atob(rawLicenseBase64)

      // Convert that string into a Uint8Array and replace the responseBody data to
      // feed it to the Widevine CDM.
      const data = new Uint8Array(rawLicenseString.length)
      for (let i = 0; i < rawLicenseString.length; ++i) {
        data[i] = rawLicenseString.charCodeAt(i)
      }
      return data
    } catch (error) {
      return responseBody
    }
  }

  return {
    configHlsDrm,
  }
}

export default useDrmHls
